import React from 'react';
import { Container, Typography, Button, Box, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import App from './App';
import meme from './meme.jpeg';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3),
}));

function Homepage() {
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const handleBackAction = () => {
        setSelectedCategory(null);
    };

    if (selectedCategory) {
        return <App category={selectedCategory} backAction={handleBackAction} />;
    }

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h5" sx={{ fontSize: '2.2rem' }} gutterBottom>
                PRICEDOUT.LOL
            </Typography>
            <Typography variant="body1" paragraph>
                Guess the price and get 1 point for every dollar away from the true price. Just like in golf, the goal is to get the lowest score.
            </Typography>
            <Typography variant="body1" paragraph>
                If the item costs $500 and you guess $400 or $600, you'll get 100 points. If you guess $495 or $505, you'll get 5 points.
            </Typography>
            <Box sx={{ my: 4 }}>
                <CardMedia
                    component="img"
                    image={meme}
                    alt="Meme"
                    sx={{ maxWidth: '100%', height: 'auto', margin: 'auto' }}
                />
            </Box>
            <Typography variant="body1" paragraph>
                If you like what you see, there's a Buy link at the bottom after you guess.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
                Choose a category to get started:
            </Typography>
            <Box>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => setSelectedCategory('mens_accessories')}
                >
                    Men's Accessories
                </StyledButton>
            </Box>
            <Typography variant="body2" sx={{ mt: 4 }}>
                Created by Max Chiswick
            </Typography>
        </Container>
    );
}

export default Homepage;
